import { useSession } from 'next-auth/react';
import { useMemo } from 'react';
import type { UserInfoInterface } from '../types/user';
import type { PaymentMethodDetail } from '../types/financial';
import type { Pagination } from '../types/pagination';
import type { Return } from '~hooks/use-request';
import useRequest from '~hooks/use-request';
import { useAgent } from '~context/agent';
import getDisplayName from '~libs/get-display-name';

export const useUserInfo = (): Return<UserInfoInterface, unknown> & {
  displayName: string;
} => {
  const { data: session } = useSession();
  const { agentInfo } = useAgent();
  const { data: userInfo, ...rest } = useRequest(
    session
      ? {
          url: '/users/me',
        }
      : undefined,
    {
      initialData: session?.user,
    },
  );
  const displayName = useMemo(() => {
    return getDisplayName(userInfo?.username, agentInfo?.prefixUsername);
  }, [userInfo?.username, agentInfo?.prefixUsername]);
  return {
    data: userInfo,
    displayName,
    ...rest,
  };
};

export const useUserPaymentMethods = (params: {
  page: number;
  perPage: number;
}): {
  data: Pagination<PaymentMethodDetail[]> | undefined;
  mutate: any;
  isLoading: boolean;
} => {
  return useRequest<Pagination<PaymentMethodDetail[]> | undefined>({
    method: 'GET',
    url: '/users/payment-methods',
    params,
  });
};
