export enum CurrencyEnum {
  THB = 'THB',
  VND = 'VND',
  SGD = 'SGD',
  MYR = 'MYR',
  IDR = 'IDR',
  PHP = 'PHP',
  MMK = 'MMK',
  LAK = 'LAK',
  KHR = 'KHR',
  BND = 'BND',
}

export const DefaultCurrencyFormat = '0,0.00';
