import type { KeyedMutator } from 'swr';
import type { AxiosResponse, Method } from 'axios';
import type { LottoLobbyInterface, ResLottoInterface } from '../types/lotto';
import type { Pagination } from '../types/pagination';
import useRequest, { type Config } from '~hooks/use-request';
import { useMediaImagePaths } from '~hooks/use-media';

export const useLotto = (params: {
  currency?: string;
}): {
  data: Pagination<ResLottoInterface[]> | undefined;
  isLoading: boolean;
  mutate: KeyedMutator<AxiosResponse<Pagination<ResLottoInterface[]>>>;
} => {
  const { withImageLogoPath } = useMediaImagePaths();

  const { data, ...rest } = useRequest<Pagination<ResLottoInterface[]>>({
    method: 'GET',
    url: '/lottos',
    params: {
      ...params,
      includeExpire: 1,
    },
  });

  const getProviderWithLogoPath = (
    providers: Pagination<ResLottoInterface[]> | undefined,
  ) => {
    return (
      providers?.records?.map((provider) => {
        const logo = withImageLogoPath(provider);
        return {
          ...provider,
          logo,
        };
      }) || []
    );
  };

  return {
    ...rest,
    data: {
      ...data,
      records: getProviderWithLogoPath(data),
    },
  };
};

export const useLottoLobby = (
  currency: string,
  provider: string,
  config?: Config<LottoLobbyInterface[]>,
): {
  data: LottoLobbyInterface[] | undefined;
  isLoading: boolean;
  mutate: KeyedMutator<AxiosResponse<LottoLobbyInterface[]>>;
} => {
  const { withImageThumbnailPath } = useMediaImagePaths();

  const { data, ...rest } = useRequest<LottoLobbyInterface[]>(
    provider
      ? {
          method: 'GET' as Method,
          url: `/lottos/${provider}/games`,
          params: {
            currency,
          },
        }
      : null,
    config,
  );

  const getProviderWithThumbnailPath = (
    providers: LottoLobbyInterface[] | undefined,
  ) => {
    return (
      providers?.map((game) => {
        const thumbnail = withImageThumbnailPath(game);
        return {
          ...game,
          thumbnail,
        };
      }) || []
    );
  };

  return {
    ...rest,
    data: getProviderWithThumbnailPath(data),
  };
};
