import numeral from 'numeral';
import { CurrencyEnum, DefaultCurrencyFormat } from './constant/etc';

const currencyConfig: Record<
  string,
  { format: string; replaceComma?: boolean }
> = {
  [CurrencyEnum.VND]: { format: '0,0.000', replaceComma: true },
  [CurrencyEnum.THB]: { format: DefaultCurrencyFormat },
  [CurrencyEnum.PHP]: { format: DefaultCurrencyFormat },
};

export const currencyFormat = (
  balance: number | undefined,
  currency: CurrencyEnum = CurrencyEnum.THB,
  format?: string,
): string => {
  if (balance === undefined) {
    return '-';
  }

  const { format: defaultFormat, replaceComma } = currencyConfig[currency];

  const selectedFormat = format || defaultFormat;
  let formattedBalance = numeral(balance).format(selectedFormat);

  if (replaceComma) {
    formattedBalance = formattedBalance.replace(/,/g, '.');
  }

  return formattedBalance;
};
