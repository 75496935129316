import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from '~hooks/use-router';
import { useAllGames } from '~hooks/use-gaming';
import PageUrls from '~constants/page-urls';
import ProviderLoadingTemplate from '~components/widget/provider-loading-template';
import { ArrangementEnum } from '~constants/etc';
import ImageCustomWidget from '~components/widget/image-custom-widget';
import BentoMenuWidget from '~components/widget/bento-menu-widget';

const GamingProviderContainer: FC<{
  currency: string;
}> = ({ currency }) => {
  const router = useRouter();
  const { t } = useTranslation('errors');
  const { data: providersGaming, isLoading: isLoadingProvidersGaming } =
    useAllGames({
      currency,
    });

  const handleOpenGamingProvider = (id: string): void => {
    router.push(`${PageUrls.GAMING}/${id}`);
  };

  if (isLoadingProvidersGaming) {
    return <ProviderLoadingTemplate />;
  }

  return (
    <div className="flex flex-col gap-2">
      {providersGaming?.records?.map((provider, i) => (
        <BentoMenuWidget
          arrangement={ArrangementEnum.Horizontal}
          icon={
            <ImageCustomWidget
              alt={`game-${provider.id}`}
              className="sm:mt-0"
              image={provider?.logo || ''}
            />
          }
          key={i}
          onClick={() => {
            handleOpenGamingProvider(provider.id);
          }}
          text={provider.title || t('not-found-provider')}
        />
      )) || []}
    </div>
  );
};

export default GamingProviderContainer;
