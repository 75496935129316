const PageUrls = {
  HOME: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  FORCE_CHANGE_PASSWORD: 'force-change-password',
  CONTACT: '/contact',
  FINANCIAL_DEPOSIT: '/financial/deposit',
  FINANCIAL_DEPOSIT_PROMOTION: '/financial/deposit/promotion',
  FINANCIAL_WITHDRAW: '/financial/withdraw',
  FINANCIAL_TRANSACTION: '/financial/transaction',
  AFFILIATE: '/affiliate',
  AFFILIATE_REVENUE: '/affiliate/revenue',
  AFFILIATE_MEMBER: '/affiliate/member',
  AFFILIATE_REPORT: '/affiliate/report',
  AFFILIATE_REPORT_DAILY: '/affiliate/report/daily-report',
  SPORT: '/sportsbooks',
  GAMING: '/gaming',
  CASINO: '/casinos',
  MINI_GAME: '/mini-game',
  TOURNAMENT: '/tournament',
  REWARD: '/rewards',
  ACCOUNT: '/account',
  PROFILE: '/profile',
  PAYMENT_METHODS: '/payment-methods',
  CHANGE_PASSWORD: '/change-password',
  ANNOUNCE: '/announce',
  NEWS: '/news',
  BET_TRANSACTIONS_SPORT: '/bet-transactions/sportsbooks',
  BET_TRANSACTIONS_CASINO: '/bet-transactions/casinos',
  BET_TRANSACTIONS_GAMING: '/bet-transactions/gaming',
  MISSIONS: '/missions',
  MY_MISSION: '/missions/my-missions',
  HISTORY_MISSION: '/missions/history-missions',
  OPEN_GAME: '/open-game',
  LOTTO: '/lottos',
};

export default PageUrls;
