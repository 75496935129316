import { useCallback } from 'react';
import type {
  CasinoLobbyInterface,
  ProviderCasinoResApiInterface,
} from '../types/casino';
import type {
  GamingLobbyInterface,
  ProviderGamingListResApi,
} from '../types/gaming';
import type {
  ProviderSportResApiInterface,
  SportsbookLobbyInterface,
} from '../types/sport';
import type { LottoLobbyInterface, ResLottoInterface } from '../types/lotto';
import type { GameFeatureListsResApi } from '../types/provider';
import getMediaFromBucket from '~libs/get-media-from-bucket';
import { MediaTypeEnum } from '~constants/etc';

type ImageLogoPathType = (
  | ProviderCasinoResApiInterface
  | ProviderGamingListResApi
  | ProviderSportResApiInterface
  | ResLottoInterface
  | GameFeatureListsResApi
) & { providerId?: string };

type ImageThumbnailPathType =
  | CasinoLobbyInterface
  | GamingLobbyInterface
  | SportsbookLobbyInterface
  | LottoLobbyInterface;

export const useMediaImagePaths = (): {
  withImageLogoPath: (provider: ImageLogoPathType) => string;
  withImageThumbnailPath: (game: ImageThumbnailPathType) => string;
} => {
  const getLogoImageName = (providerId: string, isLightMode: boolean): string =>
    `${providerId}-logo${isLightMode ? '-dark' : ''}`;

  const withImageLogoPath = useCallback((provider: ImageLogoPathType) => {
    const isLightMode = document.documentElement.className.includes('light');
    const logoName = getLogoImageName(
      String(provider.id || provider.providerId),
      isLightMode,
    );

    return getMediaFromBucket({
      type: MediaTypeEnum.Images,
      name: logoName,
      provider: provider.id || provider.providerId,
      dir: '/logo',
      extension: 'png',
    });
  }, []);

  const withImageThumbnailPath = useCallback((game: ImageThumbnailPathType) => {
    return getMediaFromBucket({
      type: MediaTypeEnum.Images,
      name: 'thumbnail',
      provider: game.provider,
      gameCode: game.gameCode,
      extension: 'webp',
    });
  }, []);

  return {
    withImageLogoPath,
    withImageThumbnailPath,
  };
};
