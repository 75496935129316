import type { FC } from 'react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from 'class-merge';
import { proxied } from 'radash';
import { parseCookies } from 'nookies';
import CasinoProviderContainer from './casino-provider-container';
import GamingProviderContainer from './gaming-provider-container';
import PageUrls from '~constants/page-urls';
import MenuIcon from '~components/widget/menu-icon';
import {
  CurrencyEnum,
  DEFAULT_CURRENCY,
  HomepageMenuEnum,
} from '~constants/etc';
import { useRouter } from '~hooks/use-router';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';
import SportProviderContainer from '~containers/layout/sport-provider-container';
import getPathLottoUrl from '~libs/get-path-lotto-url';
import HttpErrorHandler from '~components/http-error-handler';
import { useLotto } from '~hooks/use-lotto';

const GameLobby: FC = () => {
  return <>game</>;
};

const HomepageSidebarLayout: FC = () => {
  const router = useRouter();
  const { t } = useTranslation('menus');
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;
  const { data: lotto } = useLotto({
    currency,
  });
  const cookies = parseCookies();
  const locale = cookies.NEXT_LOCALE || '';
  const isAuthorized = Boolean(userInfo);
  const [menuActive, setMenuActive] = useState<HomepageMenuEnum>(
    HomepageMenuEnum.All,
  );
  const isAllProvider = menuActive === HomepageMenuEnum.All;

  const menuLists = useMemo(() => {
    return [
      {
        type: 'icon',
        name: HomepageMenuEnum.All,
        text: 'all-provider-menu',
      },
      {
        type: 'icon',
        name: HomepageMenuEnum.Casino,
        text: 'casino-menu',
      },
      // {
      //   type: 'icon',
      //   name: HomepageMenuEnum.Gamepaca,
      //   text: 'mini-game-menu',
      // },
      {
        type: 'icon',
        name: HomepageMenuEnum.Gaming,
        text: 'gaming-menu',
      },
      {
        type: 'icon',
        name: HomepageMenuEnum.Sport,
        text: 'sport-menu',
      },
      {
        type: 'icon',
        name: HomepageMenuEnum.Lotto,
        text: 'lotto-menu',
        hidden: walletInfo?.currency === CurrencyEnum.VND || !lotto?.count,
      },
      {
        type: 'icon',
        name: HomepageMenuEnum.Reward,
        text: 'mission-menu',
        hidden: !isAuthorized,
      },
      {
        type: 'icon',
        name: HomepageMenuEnum.Affiliate,
        text: 'affiliate-menu',
        hidden: Boolean(isAuthorized),
      },
      {
        type: 'icon',
        name: HomepageMenuEnum.Announcement,
        text: 'announcement-menu',
      },
      {
        type: 'icon',
        name: HomepageMenuEnum.Contact,
        text: 'contact-menu',
      },
    ];
  }, [isAuthorized, walletInfo]);

  const handleOpenLotto = async (): Promise<void> => {
    if (!isAuthorized) {
      return router.push(PageUrls.LOGIN);
    }
    try {
      window.open(
        await getPathLottoUrl(lotto?.[0].id, currency, walletInfo?.objectId),
        '_blank',
      );
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  const menuAction = proxied((prop: HomepageMenuEnum) => {
    switch (prop) {
      case HomepageMenuEnum.Lotto:
        return () => handleOpenLotto();
      case HomepageMenuEnum.Reward:
        return () => router.push(PageUrls.REWARD);
      case HomepageMenuEnum.Affiliate:
        return () => router.push(PageUrls.AFFILIATE);
      case HomepageMenuEnum.Announcement:
        return () => router.push(PageUrls.ANNOUNCE);
      case HomepageMenuEnum.Contact:
        return () => router.push(PageUrls.CONTACT);
      default:
        return (menu: HomepageMenuEnum) => {
          setMenuActive(menu);
        };
    }
  });

  const mainContents = proxied((prop: HomepageMenuEnum) => {
    switch (prop) {
      case HomepageMenuEnum.Casino:
        return (
          <CasinoProviderContainer
            currency={currency}
            locale={locale}
            walletObjectId={walletInfo?.objectId}
          />
        );
      case HomepageMenuEnum.Gamepaca:
        return <GameLobby />;
      case HomepageMenuEnum.Gaming:
        return <GamingProviderContainer currency={currency} />;
      case HomepageMenuEnum.Sport:
        return (
          <SportProviderContainer
            currency={currency}
            isAuthorized={isAuthorized}
            walletObjectId={walletInfo?.objectId}
          />
        );
      default:
        return (
          <div className="text-color/50 mt-2 flex items-center justify-center">
            ไม่พบเนื้อหาที่ท่านต้องการ
          </div>
        );
    }
  });

  return (
    <div className="mt-4">
      <div className="grid grid-cols-3 gap-2">
        <div className="flex flex-col gap-2">
          {menuLists.map((menu, i) => (
            <div
              aria-hidden
              className={cn(
                'bg-color-evaluated sm:text-md hover:bg-color-primary text-color flex cursor-pointer items-center gap-2 rounded-lg p-3 text-sm hover:text-white sm:px-4',
                menuActive === menu.name && 'bg-color-primary text-white',
                menu.hidden && 'hidden',
              )}
              key={i}
              onClick={() => {
                menuAction[menu.name](menu.name);
              }}
              role="button"
            >
              <MenuIcon menu={{ ...menu, className: 'text-sm sm:text-xl' }} />
              <p className="truncate">{t(`${menu.text}` as any)}</p>
            </div>
          ))}
        </div>
        <div className="col-span-2">
          {isAllProvider ? (
            <div className="flex flex-col gap-2">
              {[
                HomepageMenuEnum.Casino,
                HomepageMenuEnum.Gaming,
                HomepageMenuEnum.Sport,
              ].map((category, i) => (
                <div key={i}>{mainContents[category]}</div>
              ))}
            </div>
          ) : (
            mainContents[menuActive]
          )}
        </div>
      </div>
    </div>
  );
};

export default HomepageSidebarLayout;
