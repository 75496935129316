import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ProviderCasinoResApiInterface } from '../../types/casino';
import { useCasinos } from '~hooks/use-casinos';
import PageUrls from '~constants/page-urls';
import ProviderLoadingTemplate from '~components/widget/provider-loading-template';
import ImageCustomWidget from '~components/widget/image-custom-widget';
import BentoMenuWidget from '~components/widget/bento-menu-widget';
import { ArrangementEnum } from '~constants/etc';
import { useRouter } from '~hooks/use-router';

const CasinoProviderContainer: FC<{
  locale: string;
  currency: string;
  walletObjectId: string | undefined;
}> = ({ currency }) => {
  const router = useRouter();
  const { t } = useTranslation('errors');
  const { data: providersCasino, isLoading: isLoadingProvidersCasino } =
    useCasinos({
      currency,
    });

  // const onOpenCasinoProvider = (game: ProviderCasinoResApiInterface): void => {
  //   try {
  //     const currencyFiltered = game.currencies
  //       .filter((x) => x.includes(currency))
  //       .at(0);
  //     const queryString = objectToQueryString({
  //       category: ProviderTypeEnum.CASINO,
  //       providerId: game.objectId,
  //       currency: currencyFiltered,
  //       walletId: walletObjectId,
  //     });
  //     window.open(
  //       `/${locale}${PageUrls.OPEN_GAME}?qs=${encrypt(queryString)}`,
  //       '_self',
  //     );
  //   } catch (e) {
  //     HttpErrorHandler(e);
  //   }
  // };

  const onOpenCasinoLobby = (id: string): void => {
    router.push(`${PageUrls.CASINO}/${id}`);
  };

  const handleOpenCasino = async (
    game: ProviderCasinoResApiInterface,
  ): Promise<void> => {
    // let response: CasinoLobbyInterface[] = [];
    // try {
    //   response = await request<CasinoLobbyInterface[]>({
    //     url: `/casinos/${game.id}/games`,
    //     method: 'GET',
    //     params: {
    //       currency,
    //     },
    //   });
    // } catch (e) {
    //   HttpErrorHandler(e);
    // }
    //
    // if (response.length !== 0) {
    //   onOpenCasinoLobby(game.id);
    // } else {
    //   onOpenCasinoProvider(game);
    // }
    onOpenCasinoLobby(game.id);
  };

  if (isLoadingProvidersCasino) {
    return <ProviderLoadingTemplate />;
  }

  return (
    <div className="flex flex-col gap-2">
      {providersCasino?.records?.map((provider, i) => (
        <BentoMenuWidget
          arrangement={ArrangementEnum.Horizontal}
          icon={
            <ImageCustomWidget
              alt={`game-${provider.id}`}
              className="sm:mt-0"
              image={provider?.logo || ''}
            />
          }
          key={i}
          onClick={() => {
            void handleOpenCasino(provider);
          }}
          text={provider.title || t('not-found-provider')}
        />
      )) || []}
    </div>
  );
};

export default CasinoProviderContainer;
